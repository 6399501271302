/**
 * Blockquote
 */

import React from 'react';
import { graphql } from 'gatsby'

const Blockquote = ({ quote, citation }) => (
  <blockquote>
    {quote && <p className="quote">{quote}</p>}
    {citation && <cite>{citation}</cite>}
  </blockquote>
);

export default Blockquote;

export const pageBuilderBlockquoteQuery = graphql`
  fragment PageBuilderBlockquote on WordPressAcf_blockquote {
    ... on WordPressAcf_blockquote {
      quote
      citation
    }
  }
`
